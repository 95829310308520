import tw from 'tailwind-styled-components'

export const Wallets = tw.div`
    bg-[#02142b]
    w-full
    h-full
    h-screen

    max-md:overflow-y-auto
`
Wallets.Body = tw.div`
    w-full
    h-[calc(100%-theme(spacing.header))]
    px-[80px]
    flex
    gap-[60px]
    h-screen

    max-md:flex-col
    max-md:px-[0px]
    max-md:items-center
`
Wallets.Left = tw.div`
    flex-[3.5]
    w-full
    h-[80%]
    max-h-full
    max-w-full
    bg-gradient-to-t from-[#4DBDB2] to-[#599ACC]
    flex
    flex-col
    items-center
    justify-center
    rounded-[10px]

    max-md:flex-initial
    max-md:mx-[30px]
    max-md:w-[80%]
    max-md:pt-[30px]
    max-md:pb-[50px]
`
Wallets.IconCoin = tw.img`
    w-[130px]
    max-w-full

`
Wallets.LeftTitle = tw.span`
    text-[18px]
    font-[600]
    uppercase
    text-[rgba(255,255,255,0.8)]
`
Wallets.LeftMoney = tw.span`
    text-[30px]
    text-[#fff]
    font-[600]
    uppercase
    my-[5px]
`
Wallets.BoxBtn = tw.div`
    flex
    w-[80%]
    justify-center
    items-center
    mt-[20px]
    gap-[15px]
`
Wallets.BtnPull = tw.div`
    text-[#fff]
    text-[18px]
    text-center
    bg-gradient-to-l from-[#0CDA59] to-[#28D2C1]
    w-full
    py-[13px]
    rounded-[5px]
    cursor-pointer
`
Wallets.BtnPush = tw.div`
    text-[#fff]
    text-[18px]
    text-center
    w-full
    py-[13px]
    rounded-[5px]
    bg-gradient-to-l from-[#FF444E] to-[#FF5E92]
    cursor-pointer
`
Wallets.Right = tw.div`
    flex-[6.5]
    w-full

    max-md:flex-initial
    max-md:pt-[15px]
    max-md:pl-[15px]
    max-md:pr-[15px]
    max-md:h-full
    max-md:pb-[100px]
`
Wallets.RightHead = tw.div`
    flex
    justify-between
    items-center
    
    max-md:gap-[10px]
    max-md:justify-center
`
Wallets.RightTitle = tw.div`
    text-[#B9D2FF]
    text-[18px]
    font-[500]

    whitespace-nowrap
    max-md:w-full 
`
Wallets.RightInput = tw.div`
    flex
    items-center
    border-[1px]
    border-[#B9D2FF]
    border-solid
    rounded-[5px]
    px-[12px]
    py-[7px]
`
Wallets.RightInputImg = tw.img`
    w-[19px]
    h-[19px]
`
Wallets.RightInputInput = tw.input`
    bg-transparent
    placeholder:font-[300]
    outline-none
    text-[#fff]
    ml-[10px]

    max-md:w-full

`
Wallets.RightBody = tw.div`
    mt-[20px]
    w-full
    h-[72%]
`
Wallets.Table = tw.table`
    w-full
`
Wallets.TrHead = tw.tr`
    border-b-[1px]
    border-[#ccc]
    border-solid
`
Wallets.Tr = tw.tr`
`
Wallets.Th = tw.th`
    text-[#fff]
    py-[15px]
    text-start
`
Wallets.Td = tw.td`
    text-[#B9D2FF]
    h-[55px]
`
Wallets.paging = tw.div`
    flex
    w-full
    justify-center
    items-center
    mt-[20px]
`
Wallets.Boxai = tw.button`
    w-[50px]
    h-[30px]
    flex
    justify-center
    items-center
    bg-[#CADCFF]
    rounded-[3px]
    text-[20px]
    cursor-pointer
`
Wallets.Number = tw.div`
    mx-[10px]
    text-[18px]
    text-[#fff]
    font-bold
`
export const Form = tw.form`
    mt-[29px]
    w-full
    flex
    flex-col
    gap-[40px]

    max-md:gap-[20px]
    max-md:gap-[5px]
    max-md:mt-[10px]
`
Form.Title = tw.h1`
    w-full
    text-center
    text-[27px]
    font-[500]
`
Form.BoxItem = tw.div`
    flex
    gap-[19px]
    w-full

    max-md:flex-col
    max-md:gap-[7px]
`
Form.Item = tw.div`
    flex
    flex-col
    gap-[9px]
    w-full

    max-md:gap-[4px]
`
Form.Span = tw.span`
    text-[17px]
`
Form.Input = tw.input`
    outline-[#000]
    outline-[1px]
    w-full
    border-[1px]
    border-solid
    border-[#B7B7B7]
    rounded-[3px]
    py-[10px]
    px-[20px]
`
Form.BoxBtn = tw.div`
    w-full
    flex
    gap-[40px]
    justify-center
    items-center

    max-md:mt-[5px]
`
Form.CancleBtn = tw.button`
    w-[180px]
    py-[14px]
    rounded-[3px]
    bg-[#A1A1A1]
    font-[500]
    text-[17px]
    text-[#fff]

    max-md:py-[7px]
`
Form.SubmitBtn = tw.button`
    w-[180px]
    py-[14px]
    rounded-[3px]
    bg-[#0082D9]
    font-[500]
    text-[17px]
    text-[#fff]

    max-md:py-[7px]
`