import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUser, login, refeshToken } from '../../services/authServices';
import { setRefeshToken, setToken } from '../../utils/localStorage';
import Notiflix from 'notiflix';

export const fetchLogin = createAsyncThunk(
    'fetchLoginStatus',
    async (data) => {
        try {
            const resLogin = await login(data)
            await setRefeshToken(resLogin.data.refreshToken);
            await setToken(resLogin.data.accessToken);
            const user = await getUser();
            if (user.data && user.data.isLocked === true) {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure('Tài khoản bị khoá không thể truy cập')
            } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.success('Đăng nhập thành công')
                return user.data;
            }

        } catch (error) {
            if (error.response.data.code == "PASSWORD_NOT_MATCHING") {
                Notiflix.Notify.failure('mật khẩu không đúng')
            } else if (error.response.data.code == "USER_NOT_CONFIRMED") {
                Notiflix.Notify.failure('tài khoản chưa được xác thực mail')
            } else {
                Notiflix.Notify.failure('Tài khoản không tồn tại')
            }
            Notiflix.Loading.remove();
        }
    }
)

export const getUserInfor = createAsyncThunk(
    'getUserInforStatus',
    async () => {
        const user = await getUser();
        if (user.data && user.data.isLocked === false) {
            return user.data;
        }

    }
)

// export const fetchNewToken = createAsyncThunk(
//     'fetchNewToken',
//     async () => {
//         const newtoken = await refeshToken();
//         return newtoken.data.accessToken
//     }
// )