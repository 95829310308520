import React, { useState } from "react";
import loadImg from "../../assets/images/loadImg.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Axiosregister } from "../../services/authServices";
import Notiflix from "notiflix";
import { useSearchParams } from "react-router-dom";
import imageCompression from 'browser-image-compression'; // Import thư viện nén ảnh

function Register() {
  let navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [searchParams] = useSearchParams();
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [avt, setAvt] = useState(null);

  // Hàm nén ảnh
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 10, // Giới hạn ảnh xuống 1MB
      maxWidthOrHeight: 2024, // Kích thước tối đa là 1024px
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file; // Nếu có lỗi, trả về file gốc
    }
  };

  // Xử lý nén và thêm hình vào FormData
  const handleFileChange = async (event, setState) => {
    const file = event.target.files[0];
    if (file) {
      const compressedFile = await compressImage(file);
      setState(compressedFile); // Cập nhật file đã nén
    }
  };

  // Xử lý form đăng ký
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('fullName', data.name);
    formData.append('email', data.email.toLowerCase());
    formData.append('password', data.password);
    formData.append('phone', data.phone);

    // Thêm hình ảnh vào formData sau khi nén
    if (front) formData.append('cardFrontImage', front);
    if (back) formData.append('cardBackImage', back);
    if (avt) formData.append('cardImage', avt);

    // Xử lý mã giới thiệu (code)
    if (searchParams.get("ref")) {
      formData.append('code', searchParams.get("ref"));
      localStorage.setItem("ref", searchParams.get("ref"));
    } else {
      if (localStorage.getItem("ref")) {
        formData.append('code', localStorage.getItem("ref"));
      } else {
        formData.append('code', "W9ENVD");
      }
    }

    // Gửi request
    Notiflix.Loading.pulse();
    try {
      const response = await Axiosregister(formData);
      Notiflix.Notify.success('Đăng ký thành công');
      Notiflix.Loading.remove();
      navigate('/login');
    } catch (err) {
      Notiflix.Loading.remove();
      switch (err.response?.data?.code) {
        case "USER_ALREADY_EXIST":
          Notiflix.Notify.failure('Tài khoản đã tồn tại');
          break;
        case "BAD_REQUEST":
          Notiflix.Notify.failure('Số điện thoại không hợp lệ');
          break;
        default:
          Notiflix.Notify.failure('Đăng ký không thành công');
          break;
      }
    }
  };

  return (
    <div className="bg-white relative lg:py-40 sm:w-full">
      <div className="flex flex-col items-center justify-between pt-0 pr-10 pb-0 pl-10 mt-0 mr-auto mb-0 ml-auto max-w-7xl xl:px-5 lg:flex-row">
        <div className="flex flex-col items-center w-full pt-5 lg:pr-10 pb-20 lg:pl-10 lg:pt-20 lg:flex-row">
          <div className="w-full bg-cover relative max-w-md lg:max-w-2xl lg:w-7/12">
            <div className="flex flex-col items-center justify-center w-full h-full relative lg:pr-10">
              <img src="https://res.cloudinary.com/mediadragonedit/image/upload/v1729364515/image_br3pla.png" alt="logo" className="w-1/2" />
            </div>
          </div>
          <div className="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
            <div className="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl relative z-10">
              <p className="w-full text-4xl font-medium text-center leading-snug font-serif">Đăng Ký Best Bid</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Họ và tên</p>
                    <input type="text" className="border placeholder-gray-400 focus:outline-none focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white border-gray-300 rounded-md" name="name" {...register('name', { required: true })} />
                    {errors.name && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập họ và tên</p>}
                  </div>

                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
                    <input type="mail" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="email"
                      required
                      {...register("email", {
                        pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
                      })}
                    />
                    {errors?.email?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập email</p>}
                    {errors?.email?.type === "pattern" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập đúng định dạng email</p>}
                  </div>
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Số điện thoại</p>
                    <input type="phone" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="numberPhone"
                      required
                      {...register("phone", {
                        minLength: 10,
                        maxLength: 10
                      })}
                    />
                    {errors?.phone?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập số điện thoại</p>}
                    {errors?.phone?.type === "minLength" && <p className="text-[#FF0000] text-sm mt-2">Số điện thoại không đúng định dạng</p>}
                  </div>
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                absolute">Mật Khẩu</p>
                    <input type="password" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="password"
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}
                    />
                    {errors?.password?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập mật khẩu </p>}
                    {errors?.password?.type === "minLength" && <p className="text-[#FF0000] text-sm mt-2">Mật khẩu ít nhất 6 ký tự </p>}
                  </div>
                  <div className="w-full flex justify-between flex-row mt-[20px] max-md:flex-col max-md:items-center max-md:gap-[15px]">
                    <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnFront').click()}>
                      <label className="block text-center text-sm font-medium leading-6 text-gray-900">Mặt trước CCCD</label>
                      <div className="border placeholder-gray-400 focus:outline-none w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 text-base block bg-white border-gray-300 rounded-md">
                        <img className={front ? 'h-full' : 'w-[30px]'} src={front ? URL.createObjectURL(front) : loadImg} alt="frontCCCD" />
                      </div>
                    </div>

                    <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnback').click()}>
                      <label className="block text-center text-sm font-medium leading-6 text-gray-900">Mặt sau CCCD</label>
                      <div className="border placeholder-gray-400 focus:outline-none w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 text-base block bg-white border-gray-300 rounded-md">
                        <img className={back ? 'h-full' : 'w-[30px]'} src={back ? URL.createObjectURL(back) : loadImg} alt="backCCCD" />
                      </div>
                    </div>

                    <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnAvt').click()}>
                      <label className="block text-center text-sm font-medium leading-6 text-gray-900">Chân dung</label>
                      <div className="border placeholder-gray-400 focus:outline-none w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 text-base block bg-white border-gray-300 rounded-md">
                        <img className={avt ? 'h-full' : 'w-[30px]'} src={avt ? URL.createObjectURL(avt) : loadImg} alt="avatar" />
                      </div>
                    </div>
                  </div>

                  {/* Input ẩn để upload file */}
                  <div hidden>
                    <input id="btnFront" type="file" {...register("front", { required: true })} onChange={(event) => handleFileChange(event, setFront)} />
                    <input id="btnback" type="file" {...register("back", { required: true })} onChange={(event) => handleFileChange(event, setBack)} />
                    <input id="btnAvt" type="file" {...register("avt", { required: true })} onChange={(event) => handleFileChange(event, setAvt)} />
                  </div>

                  {/* Nút đăng ký */}
                  <div className="relative">
                    <button type="submit" className="w-full inline-block pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white bg-indigo-500 rounded-lg transition duration-200 hover:bg-indigo-600 ease">Đăng Ký</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
