import { configureStore } from '@reduxjs/toolkit'
import navResReducer from './navRes/navResSlice'
import authReducer from './auth/authSlice'
import resetPassReducer from './resetpass/resetpassSlice'
import valueReducer from './value/valueSlice'
import transactionReducer from './transaction/transaction'
import transactionHistoryReducer from './historyTranSation/historyTranSationSlice'
import nenReducer from './nen/nenSlice'

export const store = configureStore({
    reducer: {
        navRes: navResReducer,
        auth: authReducer,
        resetPass: resetPassReducer,
        value: valueReducer,
        transaction: transactionReducer,
        transactionHistory: transactionHistoryReducer,
        nen: nenReducer,
    }
})