import Header from '../../../components/header/Header'
import React, { useEffect, useState } from 'react'
import { Wallets, Form } from './Wallet.styles'
import walletcoins from '../../../assets/images/walletcoins.png'
import iconsearch from '../../../assets/images/iconsearch.png'
import { AiOutlineRight } from 'react-icons/ai';
import { AiOutlineLeft } from 'react-icons/ai';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux'
import { walletInfo } from '../../../services/walletServices'
import { useForm } from "react-hook-form";
import { historyNap, historyTranSation, putHistoryNap, withdrawMoney } from '../../../services/transationServices'
import Notiflix from 'notiflix'
import { ChangePage } from '../../../store/historyTranSation/historyTranSationSlice'
import { format } from 'date-fns'
import CurrencyInput from 'react-currency-input-field';
import { API_ENDPOINT } from '../../../services/axiosClient';
import { getToken } from '../../../utils/localStorage'

function Wallet() {
    // state
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalPosit, setModalPosit] = useState(false);
    const [wallet, setWallet] = useState()
    const [listHis, setListHis] = useState([])
    const [page, setPage] = useState(1)
    const [info, setInfo] = useState({})
    // const [money, setMoney] = useState()
    const [value, setValue] = useState('');
    const [Img, setImg] = useState();
    const isMobile = window.innerWidth <= 768; // Define mobile breakpoint (768px)

    // format money
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const dispatch = useDispatch()
    let widths = window.screen.width;
    useEffect(() => {
        walletInfo().then(res => {
            setWallet(res.data);
        })
            .catch(err => {
                console.log(err);
            })
    }, [])
    // config modal
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: widths > 768 ? '60%' : '90%',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.4)'
        }
    }
    const customStylesNap = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : '35%',
            height: isMobile ? 'auto' : '90vh',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.4)'
        }
    }

    // modal rút
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    // modal nạp
    function openModalNAP() {
        setModalPosit(true);
    }
    function closeModalNAP() {
        setModalPosit(false);
    }

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors }
    } = useForm();

    // handle rút tiền
    const onSubmit = (data) => {
        Notiflix.Loading.pulse()
        // xử lý tiền
        // const newdata = data
        // const test = data.amount.replace('$', '')
        // const test2 = test.replace(',', '.')
        data.amount = Number(data.amount)
        setTimeout(() => {
            withdrawMoney(data)
                .then(res => {
                    Notiflix.Loading.remove()
                    Notiflix.Notify.success('Gửi lệnh thành công');
                    setIsOpen(false);
                })
                .catch(err => {
                    Notiflix.Loading.remove()
                    Notiflix.Notify.failure('Gửi lệnh không thành công');
                })
        }, 1000);
    }
    // handle get list history nạp rút
    const parmas = useSelector(state => state.transactionHistory)
    useEffect(() => {
        historyTranSation(parmas)
            .then(res => {
                setListHis(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [page])
    // dispatch(ChangePage(1))
    const handleNext = () => {
        dispatch(ChangePage(page + 1))
        setPage(page + 1)
    }
    const handlePrev = () => {
        dispatch(ChangePage(page - 1))
        setPage(page - 1)
    }
    // get info bank
    useEffect(() => {
        historyNap()
            .then(res => {
                setInfo(res.data);
                setImg(API_ENDPOINT + '/api/user/image/' + res.data.cardImage + '?{token}&token=' + getToken());
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const handleChange = event => {
        const result = event.target.value.replace(/\D/g, '');

        setValue(result);
    };

    return (
        <Wallets>
            {/* modal start */}
            <Modal
                isOpen={modalPosit}
                onRequestClose={closeModalNAP}
                style={customStylesNap}
            >
                <div className='w-full h-full flex justify-center items-center flex-col'>
                    <h1 className='text-[25px] font-semibold'>Nạp tiền vào ví</h1>
                    <div className='flex flex-col gap-[15px] items-center'>
                        <div className='flex flex-col items-center'>
                            <p className='w-fit'>Ngân hàng:</p>
                            <p className='w-fit font-medium text-[18px] text-[#000650]'>{info?.bankName}</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='w-fit'>Số tài khoản:</p>
                            <p className='w-fit font-medium text-[18px] text-[#000650]'>{info?.bankNumber}</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='w-fit'>Chi nhánh:</p>
                            <p className='w-fit font-medium text-[18px] text-[#000650]'>{info?.bankBranch}</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='w-fit'>Tên chủ tài khoản: </p>
                            <p className='w-fit font-medium text-[18px] text-[#000650]'>{info?.accountName}</p>
                        </div>
                        {/* <div className='flex flex-col items-center'>
                            <p className='w-fit'>Số tiền cần nạp:</p>
                            <CurrencyInput
                                className='px-[15px] py-[5px] outline-none border-[1px] border-solid border-[#000650] rounded-md'
                                defaultValue={money}
                                value={money}
                                onValueChange={(value) => setMoney(value)}
                                placeholder="nhập số tiền muốn nạp"
                                suffix='đ'
                            />
                        </div>
                        <button onClick={handleNap} className='px-[20px] py-[7px] bg-[#0082D9] text-[#fff] rounded'>Nạp tiền</button> */}
                        <div className='flex flex-col items-center'>
                            <p className='w-fit'>QR Banks: </p>
                            <img src={Img} alt="qr bankd" />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <Form.Title>Rút Tiền</Form.Title>
                <Form onSubmit={handleSubmit(onSubmit)} className="max-md:h-[400px] overflow-y-auto">
                    <Form.BoxItem>
                        <Form.Item>
                            <Form.Span>Ngân hàng</Form.Span>
                            <Form.Input
                                type="text"
                                {...register("bankName", {
                                    required: true,
                                })}
                            />
                            {errors?.bankName?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập ngân hàng</p>}
                        </Form.Item>
                        <Form.Item>
                            <Form.Span>Chi nhánh</Form.Span>
                            <Form.Input
                                type="text"
                                {...register("bankBranch", {
                                    required: true,
                                })}
                            />
                            {errors?.bankBranch?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập chi nhánh </p>}
                        </Form.Item>
                    </Form.BoxItem>
                    <Form.BoxItem>
                        <Form.Item>
                            <Form.Span>Số tài khoản</Form.Span>
                            <Form.Input
                                type="text"
                                {...register("accountNumber", {
                                    required: true,
                                    pattern: /^\d+$/
                                })}
                            />
                            {errors?.accountNumber?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập số tài khoản</p>}
                            {errors?.accountNumber?.type === "pattern" && <p className="text-[#FF0000] text-[14px]">*Nhập sai định dạng</p>}
                        </Form.Item>
                        <Form.Item>
                            <Form.Span>Tên chủ tài khoản</Form.Span>
                            <Form.Input
                                type="text"
                                {...register("accountName", {
                                    required: true,
                                })}
                            />
                            {errors?.accountName?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập tên chủ tài khoản</p>}
                        </Form.Item>
                    </Form.BoxItem>
                    <Form.Item>
                        <Form.Span>Số tiền cần rút</Form.Span>
                        {/* <Form.Input
                            value={money}
                            onValueChange={(value) => setMoney(value)}
                            type="text"
                            {...register("amount", {
                                required: true,
                                pattern: /^\d+$/
                            })}
                        /> */}
                        <input
                            className='outline-[#000] outline-[1px] w-full border-[1px] border-solid border-[#B7B7B7] rounded-[3px] py-[10px] px-[20px]'
                            {...register("amount", {
                                required: true,
                                pattern: /^\d+$/
                            })}
                            // type="number"
                            value={value}
                            onChange={handleChange}
                        />
                        {errors?.amount?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập số tiền </p>}
                        {/* <CurrencyInput
                            className='outline-[#000]
                                outline-[1px]
                                w-full
                                border-[1px]
                                border-solid
                                border-[#B7B7B7]
                                rounded-[3px]
                                py-[10px]
                                px-[20px]'
                            defaultValue={money}
                            value={money}
                            onValueChange={(value) => setMoney(value)}
                            placeholder="nhập số tiền muốn nạp"
                            prefix='$'
                            {...register("amount", {
                                required: true,
                            })}
                        /> */}
                        {/* {errors?.amount?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập số tiền </p>} */}
                        {/* {errors?.amount?.type === "pattern" && <p className="text-[#FF0000] text-[14px]">*Nhập sai định dạng</p>} */}
                    </Form.Item>
                    <Form.Item>
                        <Form.Span>Ghi chú</Form.Span>
                        <Form.Input
                            type="text"
                            {...register("note", {
                                required: true,
                            })}
                        />
                        {errors?.note?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập ghi chú</p>}
                    </Form.Item>
                    <Form.BoxBtn>
                        <Form.CancleBtn onClick={closeModal}>Hủy</Form.CancleBtn>
                        <Form.SubmitBtn>Rút tiền</Form.SubmitBtn>
                    </Form.BoxBtn>
                </Form>
            </Modal>
            {/* modal end */}
            <Header title='ví của bạn' />
            <Wallets.Body>
                {/* left page */}
                <Wallets.Left>
                    <Wallets.IconCoin src={walletcoins} />
                    <Wallets.LeftTitle>Tổng tiền</Wallets.LeftTitle>
                    <Wallets.LeftMoney>{formatter.format(wallet?.money)}</Wallets.LeftMoney>
                    <Wallets.BoxBtn>
                        <Wallets.BtnPull onClick={openModalNAP}>Nạp tiền</Wallets.BtnPull>
                        <Wallets.BtnPush onClick={openModal}>Rút tiền</Wallets.BtnPush>
                    </Wallets.BoxBtn>
                </Wallets.Left>
                {/* right page */}
                <Wallets.Right>
                    <Wallets.RightHead>
                        <Wallets.RightTitle>Lịch sử giao dịch</Wallets.RightTitle>
                        <Wallets.RightInput>
                            <Wallets.RightInputImg src={iconsearch} />
                            <Wallets.RightInputInput type="text" placeholder="Search" />
                        </Wallets.RightInput>
                    </Wallets.RightHead>
                    <Wallets.RightBody>
                        <Wallets.Table>
                            <thead>
                                <Wallets.TrHead>
                                    <Wallets.Th>STT</Wallets.Th>
                                    <Wallets.Th>Ngày</Wallets.Th>
                                    <Wallets.Th>Giao dịch</Wallets.Th>
                                    <Wallets.Th>Tiền</Wallets.Th>
                                    {/* <Wallets.Th>Đầu tư</Wallets.Th> */}
                                </Wallets.TrHead>
                            </thead>
                            <tbody>
                                {
                                    listHis?.data?.map((item, index) => (
                                        <Wallets.Tr key={index}>
                                            <Wallets.Td>{(page * 8 + index + 1) - 8}</Wallets.Td>
                                            <Wallets.Td>{format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}</Wallets.Td>
                                            <Wallets.Td>{item.type == 'DEPOSIT' ? 'Nạp' : 'Rút'}</Wallets.Td>
                                            <Wallets.Td className={item.type == 'DEPOSIT' ? 'text-[#23FF49]' : 'text-[#FF6E75]'}>{item.type == 'DEPOSIT' ? '+' : '-'}{formatter.format(item.amount)}</Wallets.Td>
                                            {/* <Wallets.Td>$5.00</Wallets.Td> */}
                                        </Wallets.Tr>
                                    ))
                                }
                            </tbody>
                        </Wallets.Table>
                        <Wallets.paging>
                            <Wallets.Boxai onClick={handlePrev} disabled={page <= 1 ? true : false}><AiOutlineLeft /></Wallets.Boxai>
                            <Wallets.Number>
                                <span>{page}</span>
                                <span>/</span>
                                <span>{listHis?.meta?.pageCount}</span>
                            </Wallets.Number>
                            <Wallets.Boxai onClick={handleNext} disabled={page >= listHis?.meta?.pageCount ? true : false}><AiOutlineRight /></Wallets.Boxai>
                        </Wallets.paging>
                    </Wallets.RightBody>
                </Wallets.Right>
            </Wallets.Body>
        </Wallets>
    )
}

export default Wallet