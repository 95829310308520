import React, { useState } from 'react';
// import logo from '../../assets/images/logo_olymp.png';
import bell from '../../assets/images/bell.png';
import dash from '../../assets/icons/trade.svg';
import pie from '../../assets/images/pie.png';
import wallet from '../../assets/images/wallet.png';
import account from '../../assets/images/account.png';
import logOut from '../../assets/images/exit.svg';
import close from '../../assets/images/close.png';
import { NavWapper } from './Nav.styles';
import { useLocation, useNavigate } from 'react-router-dom'
import { changeIsOpen } from '../../store/navRes/navResSlice';
import { useDispatch, useSelector } from 'react-redux'
import { isOpen } from '../../store/navRes/navResSelector';
import { removeToken } from '../../utils/localStorage';
// import { getUserInfor } from '../../store/auth/authThunk';

function Nav() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const isopen = useSelector(isOpen)
    const Nav = isopen ? NavWapper.Open : NavWapper;

    const handleLogOut = () => {
        removeToken()
        navigate('/')
        window.location.reload();
    }
    return (
        <Nav>
            <NavWapper.IconClose
                src={close}
                onClick={() => dispatch(changeIsOpen(false))}
            />
            <NavWapper.Ul>
                <li className='max-md:hidden flex items-center justify-center w-full h-[90px]'>
                    <img src="https://res.cloudinary.com/mediadragonedit/image/upload/v1729364202/ndzsm0vwestwagqwhd6i_ist9e2.webp" className='w-[45px]' />
                </li>
                <NavWapper.Li>
                    <NavWapper.Img src={bell} />
                    <NavWapper.Span className='md:hidden'>Thông báo</NavWapper.Span>
                </NavWapper.Li>
                <NavWapper.Li
                    onClick={() => {
                        navigate('/dashboard')
                        dispatch(changeIsOpen(false))
                    }}
                    className={pathname === '/dashboard' ? 'bg-[#465991]' : ''}
                >
                    <NavWapper.Img src={dash} />
                    <NavWapper.Span>Giao Dịch</NavWapper.Span>
                </NavWapper.Li>
                <NavWapper.Li
                    onClick={() => {
                        navigate('/statics')
                        dispatch(changeIsOpen(false))
                    }}
                    className={pathname === '/statics' ? 'bg-[#465991]' : ''}
                >
                    <NavWapper.Img src={pie} />
                    <NavWapper.Span>Thống kê</NavWapper.Span>
                </NavWapper.Li>
                <NavWapper.Li
                    onClick={() => {
                        navigate('/wallet')
                        dispatch(changeIsOpen(false))
                    }}
                    className={pathname === '/wallet' ? 'bg-[#465991]' : ''}
                >
                    <NavWapper.Img src={wallet} />
                    <NavWapper.Span>Ví</NavWapper.Span>
                </NavWapper.Li>
                <NavWapper.Li
                    onClick={() => {
                        navigate('/account')
                        dispatch(changeIsOpen(false))
                    }}
                    className={pathname === '/account' ? 'bg-[#465991]' : ''}
                >
                    <NavWapper.Img src={account} />
                    <NavWapper.Span>Tài khoản</NavWapper.Span>
                </NavWapper.Li>
            </NavWapper.Ul>
            <NavWapper.Out onClick={handleLogOut}>
                <NavWapper.Img className='w-[25px] h-[25px] max-md:w-[30px] max-md:h-[30px]' src={logOut} />
                <NavWapper.Span>Đăng xuất</NavWapper.Span>
            </NavWapper.Out>
        </Nav>
    )
}

export default Nav