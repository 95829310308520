import AxiosClient from "./axiosClient";

// ví dụ
const API_ENDPOINT = "api/user/account" 

export const getListUsers = () => {
   return AxiosClient.get(API_ENDPOINT)
}

export const UpdateUsers = (data) => {
   return AxiosClient.patch(API_ENDPOINT,data)
}

export const ChangePassWord = (data) => {
   return AxiosClient.post('api/user/forgot-password-admin', data)
}