import tw from 'tailwind-styled-components'

export const Charts = tw.div`
    mx-[5px]
    h-full

    max-md:px-[15px]
    max-md:mx-[0px]
`
Charts.Header = tw.div`
    h-header
    flex
    justify-center
    items-center
    overflow-hidden
`
Charts.Body = tw.div`
    h-[calc(100%-theme(spacing.header))]
    flex
    flex-col

    max-md:h-full
`
Charts.ChartBox = tw.div`
    flex-[7]
    pt-[10px]

    max-md:flex-initial
    max-md:w-full
    max-md:h-[400px]
`
Charts.History = tw.div`
    flex-[3]
    border-[1px]
    border-solid
    border-[#465991]
    rounded-[5px]
    mt-[5px]
    flex
    flex-col
    bg-[#02142b]

    max-md:hidden
`
Charts.HistoryHead = tw.div`
    flex
    items-center
    gap-[14px]
    px-[10px]
    py-[10px]
    border-b-[1px]
    border-solid
    border-[#465991]
    bg-[#17254E]
`
Charts.HistoryUp = tw.div`
    flex
    gap-[10px]
    text-[#23FF49]
    bg-[rgba(35,355,73,0.2)]
    px-[10px]
    py-[6px]
    border-[1px]
    border-solid
    border-[#23FF49]
    rounded-[3px]
`
Charts.HistoryDown = tw.div`
    flex
    gap-[10px]
    text-[#FF6E75]
    bg-[rgba(255,107,111,0.2)]
    px-[10px]
    py-[6px]
    border-[1px]
    border-solid
    border-[#FF6E75]
    rounded-[3px]
`
Charts.HistoryBiList = tw.div`
    flex-1
    flex
    justify-around
    items-center
    my-auto
`
Charts.HistoryBi = tw.div`
    px-[10px]
    py-[20px]
    grid 
    gap-[10px]
    grid-cols-5
    grid-rows-4
    grid-flow-row-dense
    w-fit

    2xl:py-[10px]
    `
    Charts.HistoryBiItem = tw.div`
    w-[20px]
    h-[20px]
    rounded-[50%]
    bg-[#E9E7E7]
    
    2xl:w-[15px]
    2xl:h-[15px]
`
// responseType
Charts.HeaderRe = tw.div`
    h-[67px]
    flex
    flex-col
    justify-between
    items-center
    w-full
    border-[1px]
    border-solid
    border-[#465991]
    rounded-[5px]
    py-[7px]
    mb-[6px]

    md:hidden
`
Charts.HeaderTitleRe = tw.span`
    text-[#93B2EC]
`
Charts.HeaderBoxCoinRe = tw.div`
    flex 
    items-center
    gap-[15px]
`
Charts.TitleRe = tw.div`
    md:hidden
    flex
    justify-between
    w-full
    py-[10px]
    items-center
`
Charts.TitleText = tw.span`
    text-[#fff]
    text-[18px]
    font-[600]
`
Charts.TitleIcon = tw.img`
    w-[35px]
    h-[35px]
`