import React from "react";
import { RateStats } from "./RateWinLose.styles";

function RateWinLose(data) {
  return (
    <RateStats>
      <RateStats.SubTitle>Giao dịch của bạn</RateStats.SubTitle>
      <RateStats.Content>
        <RateStats.ContentWin>
          <RateStats.TextSub>Tỉ lệ thắng: &nbsp;</RateStats.TextSub>
          <RateStats.TextRateWin>
            {Number(data.data?.percentWin)?.toFixed(1)}<RateStats.SysText>%</RateStats.SysText>
          </RateStats.TextRateWin>
        </RateStats.ContentWin>
        <RateStats.ContentLose>
          <RateStats.SpaceDiv />
          <RateStats.TextSub>Tỉ lệ thua: &nbsp;</RateStats.TextSub>
          <RateStats.TextRateLose>
            {Number(data.data?.percentLose)?.toFixed(1)}<RateStats.SysText>%</RateStats.SysText>
          </RateStats.TextRateLose>
        </RateStats.ContentLose>
      </RateStats.Content>
    </RateStats>
  );
}

export default RateWinLose;
