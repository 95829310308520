import AxiosClient from "./axiosClient";

const API_ENDPOINT = "api/user-info/balance";

export const walletInfo = () => {
    return AxiosClient.get(API_ENDPOINT)
}

export const imgAvata = ({ filename, token }) => {
    return AxiosClient.get('api/user/image/' + filename + '&{token}?token=' + token)
}