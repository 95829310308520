import Login from "../screens/login/Login";
import Register from './../screens/register/Register';
import { Routes, Route } from 'react-router-dom'
import ForgotPassword from './../screens/forgotPassword/ForgotPassword';
import EnterPassword from './../screens/forgotPassword/EnterPassword';
import Ladi from "../screens/ladi/Ladi";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Ladi />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/enterPassword" element={<EnterPassword />} />
        </Routes>
    )
}

export default PublicRoutes;