import tw from 'tailwind-styled-components'

export const BoardWrapper = tw.div`
    h-full
    w-[300px]
    mr-[10px]

    max-md:w-full
`
BoardWrapper.Header = tw.div`
    h-header
    flex
    flex-col
    justify-between
    items-center
    w-full
    border-[1px]
    border-solid
    border-[#465991]
    rounded-[5px]
    py-[5px]
    mb-[6px]
    bg-[#02142b]

    max-md:hidden
`
BoardWrapper.HeaderTitle = tw.span`
    text-[#93B2EC]
`
BoardWrapper.HeaderBoxCoin = tw.div`
    flex 
    items-center
    gap-[15px]
`
BoardWrapper.Body = tw.div`
    h-[calc(100%-theme(spacing.header)-6px)]
    flex
    flex-col
    gap-[6px]

    max-md:gap-[0]
    max-md:text-[16px]
    max-md:h-full
`
BoardWrapper.BoxTop = tw.div`
    rounded-[5px]
    border-[1px]
    border-[#465991]
    border-solid
    bg-[#02142b]
    relative
    flex-[7]
    flex
    flex-col

    max-md:border-[0px]
    max-md:mt-[15px]
`
BoardWrapper.Place = tw.div`
    px-[7px]
    py-[6px]
    border-b-[1px]
    border-[#465991]
    border-solid
    flex
    justify-between
    text-[#B9D2FF]

    max-md:hidden
`
BoardWrapper.ID = tw.div`
    px-[7px]
    py-[7px]
    border-b-[1px]
    border-[#465991]
    border-solid
    flex
    justify-between
    text-[#8FA8D8]

    max-md:border-t-[1px]
    max-md:px-[15px]
`
BoardWrapper.Value = tw.div`
    px-[7px]
    py-[13px]
    flex
    flex-col
    gap-[7px]
    justify-between

    max-md:px-[15px]
`
BoardWrapper.BoxValue = tw.div`
    w-full
    h-[50px]
    max-h-full
    rounded-[5px]
    bg-[#313F6C]
    flex
`
BoardWrapper.BoxValueLeft = tw.div`
    w-[60px]
    h-full
    flex
    justify-center
    items-center
`
BoardWrapper.BoxValueCenter = tw.div`
    flex-1
    flex
    justify-center
    items-center
`
BoardWrapper.BoxValueNumber = tw.div`
    w-full h-[47px] bg-[#080B32] text-[#fff] font-[600] flex justify-center items-center text-[20px]
`
BoardWrapper.BoxValueInput = tw.input`
    outline-none
    text-center
    bg-transparent
    w-[70%]

    max-md:w-[100px]
`
BoardWrapper.BoxValueRight = tw.div`
    w-[60px]
    h-full
    flex
    justify-center
    items-center
`
BoardWrapper.BoxValueOption = tw.div`
    w-full
    flex
    gap-[20px]

    max-md:hidden
`
BoardWrapper.BoxValueOptionItem = tw.div`
    w-[calc(100%/5)]
    h-[35px]
    flex
    justify-center
    items-center
    text-[#93B2EC]
    bg-[#2A3456]
    cursor-pointer
`
BoardWrapper.Control = tw.div`
    border-t-[1px]
    border-[#465991]
    border-solid
    pt-[10px]
    flex
    flex-col
    justify-between
    gap-[10px]
    justify-center
    items-center
`
BoardWrapper.Countdown = tw.div`
    w-[67px]
    h-[67px]
    rounded-[50%]
    border-[1px]
    border-solid
    border-[#fff]
    text-[#FF4952]
    text-[20px]
    font-[600]
    flex
    justify-center
    items-center
`
BoardWrapper.WrapperCount = tw.div`
    flex 
    flex-col 
    justify-center 
    items-center 
    gap-[5px] 
    text-[#B9D2FF]

    max-md:hidden
`

BoardWrapper.BtnUP = tw.button`
    w-full
    flex
    gap-[15px]
    justify-center
    items-center
    text-[#fff]
    text-[16px]
    bg-gradient-to-r from-[#00D87A] to-[#24CF41]
    py-[7px]
    rounded-[3px]
    cursor-pointer
`
BoardWrapper.BtnDown = tw.button`
    w-full
    flex
    gap-[15px]
    justify-center
    items-center
    text-[#fff]
    text-[16px]
    bg-gradient-to-r from-[#E82D6A] to-[#F03D46]
    py-[7px]
    rounded-[3px]
    cursor-pointer
`
BoardWrapper.BtnCancel = tw.button`
    w-full
    py-[7px]
    border-[1px]
    border-[rgba(255,255,255,0.5)]
    rounded-[3px]
    border-solid
    text-center
    text-[#fff]
    text-[15px]
    cursor-pointer
    bg-[rgba(255,255,255,0.1)]

    max-md:hidden
`
BoardWrapper.BoxBottom = tw.div`
    flex-[3]
    border-[1px]
    border-solid
    border-[#465991]
    rounded-[5px]
    py-[10px]
    bg-[#02142b]

    max-md:border-[0px]
    max-md:py-[0px]
    max-md:h-[300px]
    max-md:flex-initial
`
BoardWrapper.BoxBottomTitle = tw.span`
    text-[#8FA8D8]
    font-[600]
    px-[10px]

    max-md:hidden
`
BoardWrapper.Table = tw.table`
    w-full
    mt-[5px]

    max-md:mt-[0px]
    max-md:h-fit
`
BoardWrapper.Th = tw.th`
    text-center
    text-[#8FA8D8]
    font-[400]
`
BoardWrapper.Td = tw.td`
    text-center
    text-[#B9D2FF]
    font-[400]
    py-[5px]
`
BoardWrapper.TrHead = tw.tr`
    h-[35px]
    border-t-[1px]
    border-b-[1px]
    border-solid
    border-[#465991]
    rounded-[10px]
    bg-[#263B63]
`
BoardWrapper.Tr = tw.tr`
    
`
BoardWrapper.Bi = tw.div`
    w-[15px]
    h-[15px]
    rounded-[50%]
    bg-[#8FA8D8]
`
BoardWrapper.BiGreen = tw.div`
    w-[15px]
    h-[15px]
    rounded-[50%]
    bg-[#23FF49]
`
BoardWrapper.BiRed = tw.div`
    w-[15px]
    h-[15px]
    rounded-[50%]
    bg-[#F03D46]
`
// response style
BoardWrapper.Countdownres = tw.div`
    w-[60px]
    h-[60px]
    rounded-[50%]
    border-[1px]
    border-solid
    border-[#fff]
    text-[#FF4952]
    text-[20px]
    font-[600]
    flex
    justify-center
    items-center
`
BoardWrapper.WrapperCountres = tw.div`
    md:hidden
    flex 
    flex-col 
    justify-center 
    items-center 
    gap-[5px] 
    text-[#B9D2FF]
`
BoardWrapper.ValueBoxres = tw.div`
    
    flex
    justify-center
    items-center
    gap-[20px]
`
BoardWrapper.BoxCloseres = tw.div`
    md:hidden
    px-[15px]
    cursor-pointer
    rounded-[3px]
    bg-[#E9E7E7]
    flex
    justify-center
    items-center
`
BoardWrapper.History = tw.div`
    md:hidden
    flex-[3]
    rounded-[5px]
    mt-[5px]
    flex
    flex-col
`
BoardWrapper.HistoryHead = tw.div`
    flex
    items-center
    gap-[14px]
    px-[15px]
    py-[10px]
    border-b-[1px]
    border-t-[1px]
    border-solid
    border-[#465991]
`
BoardWrapper.HistoryUp = tw.div`
    flex
    gap-[10px]
    text-[#23FF49]
    bg-[rgba(35,355,73,0.2)]
    px-[10px]
    py-[6px]
    border-[1px]
    border-solid
    border-[#23FF49]
    rounded-[3px]
`
BoardWrapper.HistoryDown = tw.div`
    flex
    gap-[10px]
    text-[#FF6E75]
    bg-[rgba(255,107,111,0.2)]
    px-[10px]
    py-[6px]
    border-[1px]
    border-solid
    border-[#FF6E75]
    rounded-[3px]
`
BoardWrapper.HistoryBiList = tw.div`
    flex-1
    flex
    justify-around
    items-center
    my-auto
    w-max

`
BoardWrapper.HistoryBi = tw.div`
    px-[15px]
    py-[20px]
    grid 
    gap-[10px]
    grid-cols-5
    grid-rows-4
    w-fit
`
BoardWrapper.HistoryBiItem = tw.div`
    w-[20px]
    h-[20px]
    rounded-[50%]
    bg-[#23FF49]
`
BoardWrapper.Historypage = tw.div`
    md:hidden
    flex
    w-[51px]
    justify-between
    ml-[15px]
    mt-[10px]
    mb-[20px]
`
BoardWrapper.Navbar = tw.div`
    md:hidden
    bg-[#17254E]
    w-full
    flex
    justify-between
    px-[15px]
    items-center
    py-[10px]
    border-t-[1px]
    border-[#465991]
    border-solid
`
BoardWrapper.NavItem = tw.div`
    text-[18px]
    text-[#fff]
    w-full
    px-[15px]
    py-[5px]
    text-center
    rounded-[3px]
`
BoardWrapper.Notication = tw.div`
    hidden
    max-md:hidden
    absolute
    top-0
    left-0
    right-0
    bottom-0
    z-[10]
    bg-[rgba(0,0,0,0.8)]
    flex-col
    justify-center
    items-center
    gap-[20px]
`
BoardWrapper.NoticationBtn = tw.img`
    absolute
    top-[15px]
    right-[15px]
    w-[30px]
`
BoardWrapper.NoticationIcon = tw.img`
    w-[190px]
`
BoardWrapper.NoticationValue = tw.span`
    text-[20px]
    font-[500]
`