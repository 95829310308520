import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: 'FREE',
    reRender: '',
}

const transaction = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        changeType: (state, action) => {
            state.type = action.payload;
        },
        reRender: (state, action) => {
            state.reRender = action.payload;
        }
    }
})

export const { changeType, reRender } = transaction.actions;
export default transaction.reducer