// src/utils/localStorage.js

// Save an item to localStorage
export const setItem = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error saving to localStorage', error);
    }
};

// Get an item from localStorage
export const getItem = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        return serializedValue === null ? undefined : JSON.parse(serializedValue);
    } catch (error) {
        console.error('Error reading from localStorage', error);
        return undefined;
    }
};

// Remove an item from localStorage
export const removeItem = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing from localStorage', error);
    }
};

// Clear all items from localStorage
export const clearStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        console.error('Error clearing localStorage', error);
    }
};
const LOCAL_STORAGE_AUTH_KEY = "LOCAL_STORAGE_AUTH_BO";
export const setToken = token => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
};
export const getToken = () => {
    const jwtToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    return jwtToken || null;
};
export const setRefeshToken = token => {
    localStorage.setItem('RESFESH_TOKEN_BO', token);
};
export const getRefeshToken = () => {
    const refeshToken = localStorage.getItem('RESFESH_TOKEN_BO');
    return refeshToken || null;
};
export const removeToken = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
};