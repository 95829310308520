import tw from "tailwind-styled-components";

export const History = tw.div`
    w-8/12

    max-md:w-full
    max-md:order-last
`;

History.HeadContainer = tw.div`
    flex 
    flex-row 
    justify-between
    items-center
`;
History.TextSub = tw.span`
    flex 
    text-2xl 
    font-medium 
    text-[#B9D2FF]

    max-md:text-xl
`;
History.InputContainer = tw.div`
    flex 
    flex-row 
    w-1/4 
    text-[#FFFFFF] 
    bg-[#707070] 
    bg-opacity-20 
    h-[40px] 
    rounded-md 
    border-solid 
    border border-[#B9D2FF] 
    px-[10px] 
    py-[8px]

    max-md:w-1/2
`;
History.Img = tw.img`
`;
History.InputSearch = tw.input`
    bg-transparent 
    w-full 
    mx-[5px]
`;
History.TableHistory = tw.div`
    flex 
    flex1 
    flex-col 
    w-full 
    h-full
    mt-[12px]
`;
History.TableTitleContainer = tw.div`
    flex 
    flex-row 
    justify-between 
    text-[#FFFFFF] 
    text-lg 
    border-solid 
    border-b-2 
    border-[#B9D2FF] 
    border-opacity-30 
    py-[12px]

    max-md:text-base
`;
History.TextTitleLeftTable = tw.span`
    w-1/12 flex justify-start

    max-md:w-1/12
`;
History.TextTitleCenterTable = tw.span`
    w-2/12 flex flex1 justify-start

    max-md:w-3/12
`;
History.TextTitleRightTable = tw.span`
    w-1/12 flex justify-end

    max-md:w-2/12
`;
History.ChangePageContainer = tw.div`
    flex 
    w-full 
    justify-center 
    items-center 
    mt-[25px]
    mb-[25px]
`;
History.ButtonChangePage = tw.button`
    aspect-[15/9] 
    h-[30px]
    bg-cover
`;
History.TextPageNumber = tw.span`
    text-[#FFFFFF] px-[20px]
`;