import React, { useEffect, useState, useRef } from 'react';
import coins from '../../../../../assets/images/coins.png';
import { BoardWrapper } from './BoardControl.styles';
import up from '../../../../../assets/images/up.png';
import down from '../../../../../assets/images/down.png';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { navRes } from '../../../../../store/navRes/navResSelector';
import { changeNav } from '../../../../../store/navRes/navResSlice';
import { walletInfo } from '../../../../../services/walletServices';
import { SetCount, setValue } from '../../../../../store/value/valueSlice';
import { cancelTrading, getSessionId, historyTran, result, trading } from '../../../../../services/trandingServices';
import { changeType, reRender } from '../../../../../store/transaction/transaction';
import Notiflix from 'notiflix';
import { format } from "date-fns";
import io from 'socket.io-client';
import close from '../../../../../assets/images/close.png';
import win from '../../../../../assets/images/win.webp';
import lose from '../../../../../assets/images/lose.webp';
import { sesstionHistory } from '../../../../../services/sessionServices';

function BoardControl() {
  const [idTrans, setIdTrans] = useState();
  const [history, setHistory] = useState([]);
  const [count, setCount] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [status, setStatus] = useState(null);
  const [wallet, setWallet] = useState();
  const [results, setResults] = useState();
  const [pathOne, setPathOne] = useState([]);
  const [pathTwo, setPathTwo] = useState([]);
  const [pathThree, setPathThree] = useState([]);
  const [pathFour, setPathFour] = useState([]);
  const [pathFive, setPathFive] = useState([]);
  const [isLoadingResult, setIsLoadingResult] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);
 const [isWinImageLoaded, setIsWinImageLoaded] = useState(false); // Trạng thái tải ảnh
  const [isLoseImageLoaded, setIsLoseImageLoaded] = useState(false);  
 const [finalStatus, setFinalStatus] = useState(null); // Trạng thái cuối cùng để hiển thị
 const [showNotification, setShowNotification] = useState(false); // Thêm trạng thái cho notification
    const [isWebSocketConnected, setIsWebSocketConnected] = useState(true); // Trạng thái kết nối WebSocket
 const dispatch = useDispatch();
  const notication = useRef();
  const socketRef = useRef(null);
  // Truy xuất `userId` từ Redux store
  const userId = useSelector((state) => state.auth.user?.id);

  // Truy xuất nav từ Redux
  const nav = useSelector(navRes);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getMoney = () => {
    walletInfo()
      .then(res => setWallet(res.data.money))
      .catch(console.log);
  };

  useEffect(() => {
    getMoney();
    historyTrading();
    getHistory();
if (!sessionId && typeTran === 'FREE') {
      getSessionId()
        .then(res => {
          setSessionId(res.data.sessionId);
          dispatch(changeType(res.data.position));
          setIdTrans(res.data.id)
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, []);

  const valueNumber = useSelector(state => state.value.value);
  const typeTran = useSelector(state => state.transaction.type);

  const handleTrading = (type) => {
    if (typeTran === 'FREE') {
      dispatch(changeType(type));
      trading({ positionType: type, amount: valueNumber })
        .then(res => {
          Notiflix.Notify.success('Đặt lệnh thành công');
          setIdTrans(res.data.id);
          getMoney();
          dispatch(reRender(Math.random()));
          setSessionId(res.data.session);
          setDisplayResult(false);
        })
        .catch(err => {
          const errorCode = err.response?.data?.code;
          if (errorCode === 'NOT_ENOUGH_MONEY') {
            Notiflix.Notify.failure('Bạn không đủ tiền để cược');
            dispatch(changeType('FREE'));
          } else if (errorCode === 'WAIT_RESULT') {
            Notiflix.Notify.failure('Bạn đã đặt cược trước đó, vui lòng đợi kết quả');
          }
        });
    } else {
      Notiflix.Notify.failure('Vui lòng chờ kết quả trước khi đặt lệnh mới');
    }
  };

  const handleCancel = () => {
    cancelTrading(idTrans)
      .then(() => {
        Notiflix.Notify.success('Huỷ giao dịch thành công');
        dispatch(changeType('FREE'));
        setSessionId(null);
        getMoney();
        dispatch(reRender(Math.random()));
      })
      .catch(() => Notiflix.Notify.failure('Huỷ không thành công'));
  };

  const historyTrading = () => {
    historyTran()
      .then(res => setHistory(res.data.data))
      .catch(console.log);
  };

  const checkType = (type) => {
    switch (type) {
      case 'BUY': return '#23FF49';
      case 'SELL': return '#F03D46';
      default: return '#8FA8D8';
    }
  };
  // Thiết lập WebSocket
  const setupWebSocket = () => {
    if (!userId) return; // Chỉ kết nối nếu có userId

    socketRef.current = io(process.env.REACT_APP_APIURL, {
      query: { userId } // Gửi userId khi kết nối
    });

    // Lắng nghe sự kiện 'message' từ WebSocket cho counter
    socketRef.current.on('message', (data) => {
      setCount(data.counter);
      dispatch(SetCount(data.counter));
      setIsWebSocketConnected(true); // Kết nối WebSocket thành công
    });

    // Lắng nghe sự kiện 'trade-update' từ WebSocket
    socketRef.current.on('trade-update', (data) => {
      setSessionId(data.sessionId);
    });

    // Khi kết nối lại, gửi `sessionId` của giao dịch đang chờ lên server để kiểm tra trạng thái
    socketRef.current.on('connect', () => {
      if (sessionId) {
        // Nếu sessionId đang ở trạng thái PENDING, gửi lên server kiểm tra
        socketRef.current.emit('check-trade-status', { sessionId });
      }
    });

    // Lắng nghe kết quả từ server khi kiểm tra `sessionId`
    socketRef.current.on('trade-status-result', (data) => {
      if (data.status === 'COMPLETE') {
        // Nếu giao dịch đã hoàn tất, cập nhật giao diện
        setFinalStatus(data);
        setStatus(data);
        dispatch(changeType('FREE'));
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3500); // Hiển thị 3.5 giây
      }
    });

    // Xử lý khi có lỗi kết nối WebSocket
    socketRef.current.on('connect_error', () => {
      console.warn('Kết nối WebSocket thất bại, chuyển sang polling qua WebSocket.');
      setIsWebSocketConnected(false); // Đánh dấu kết nối WebSocket bị lỗi
    });
  };

  useEffect(() => {
    setupWebSocket();

    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    // Nếu WebSocket không kết nối, thực hiện polling qua WebSocket
    if (!isWebSocketConnected) {
      const pollingInterval = setInterval(() => {
        if (socketRef.current && !socketRef.current.connected) {
          // Tự động gửi yêu cầu lấy lại dữ liệu qua WebSocket mỗi giây
          socketRef.current.emit('polling-request', { type: 'counter' });
          socketRef.current.emit('polling-request', { type: 'trade-update' });
        }
      }, 1000);

      // Lắng nghe phản hồi từ server cho các polling request qua WebSocket
      socketRef.current.on('polling-response', (data) => {
        if (data.type === 'counter') {
          setCount(data.counter);
          dispatch(SetCount(data.counter));
        }
        if (data.type === 'trade-update') {
          setSessionId(data.sessionId);
        }
      });

      return () => clearInterval(pollingInterval);
    }
  }, [isWebSocketConnected, dispatch]);


  // Tạo hàm preload ảnh
  const preloadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve();
    });
  };

  // Đảm bảo preload ảnh thắng và thua
  useEffect(() => {
    Promise.all([preloadImage(win), preloadImage(lose)])
      .then(() => {
        setIsWinImageLoaded(true);
        setIsLoseImageLoaded(true);
      })
      .catch(console.error);
  }, []);

  const fetchResultWithRetry = async (retries = 5, delay = 500) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const res = await result();
        if (res?.data?.profit !== undefined && res?.data?.status) {
          setStatus(res.data);
 setFinalStatus(res.data); // Cập nhật finalStatus để ép kiểu
          if (isWinImageLoaded && isLoseImageLoaded) { // Kiểm tra ảnh đã tải xong
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 3500); // Hiển thị 3.5 giây
          }
          return;
        } else {
          throw new Error('Dữ liệu kết quả chưa hoàn chỉnh');
        }
      } catch (err) {
        if (attempt === retries) throw err;
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  };

 useEffect(() => {
  if (count === 59 && sessionId && !isLoadingResult && !displayResult) {
    setIsLoadingResult(true);
    fetchResultWithRetry()
      .then(() => {
        if (finalStatus) {
          setShowNotification(true);
          setTimeout(() => setShowNotification(false), 3500); // Hiển thị 3.5 giây
        }
        
        // Đặt các lệnh này bên trong .then()
        getMoney();
        historyTrading();
        setSessionId(null);
        dispatch(changeType('FREE'));
      })
      .catch(console.log)
      .finally(() => setIsLoadingResult(false));
  } else if (count !== 59) {
    setDisplayResult(false);
  }
}, [count, sessionId, isLoadingResult, displayResult]);



  const handleChangeValue = (e) => {
    const value = e.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    dispatch(setValue(value));
  };

  const getHistory = () => {
    sesstionHistory()
      .then(res => {
        setResults(res.data);
        setPathOne(res.data.pathOne);
        setPathTwo(res.data.pathTwo);
        setPathThree(res.data.pathThree);
        setPathFour(res.data.pathFour);
        setPathFive(res.data.pathFive);
      })
      .catch(console.log);
  };

  const checkColor = (bi) => {
    switch (bi?.result) {
      case 'SELL': return 'bg-[#FF6E75]';
      case 'undefined': return 'bg-[#E9E7E7]';
      default: return 'bg-[#23FF49]';
    }
  };

  const customPathOne = Array.from({ length: 20 }, (_, i) => pathOne[i] || { result: 'undefined' });
  const customPathTwo = Array.from({ length: 20 }, (_, i) => pathTwo[i] || { result: 'undefined' });
  const customPathThree = Array.from({ length: 20 }, (_, i) => pathThree[i] || { result: 'undefined' });
  const customPathFour = Array.from({ length: 20 }, (_, i) => pathFour[i] || { result: 'undefined' });
  const customPathFive = Array.from({ length: 20 }, (_, i) => pathFive[i] || { result: 'undefined' });


  return (
    <BoardWrapper>
      <BoardWrapper.Header>
        <BoardWrapper.HeaderTitle>Ví của bạn</BoardWrapper.HeaderTitle>
        <BoardWrapper.HeaderBoxCoin>
          <img className='w-[25px] h-[25px]' src={coins} />
          <span className='text-[#fff]'>{formatter.format(wallet)}</span>
        </BoardWrapper.HeaderBoxCoin>
      </BoardWrapper.Header>
      <BoardWrapper.Body>
        <BoardWrapper.BoxTop>
          <div>
            {/* place order */}
            <BoardWrapper.Place>
              <span className='font-[500]'>PLACE ORDER</span>
              <span className='text-[#465991]'>BTC / BUSD</span>
            </BoardWrapper.Place>
            {/* ID */}
            <BoardWrapper.ID>
              <span>ID:</span>
              <span>{sessionId ? sessionId : 'Chưa đặt lệnh'}</span>
            </BoardWrapper.ID>
          </div>
          {/* giá trị */}
          <BoardWrapper.Value>
            <span className='text-[#fff]'>Giá trị</span>
            <BoardWrapper.ValueBoxres>
              <BoardWrapper.BoxValue>
                <BoardWrapper.BoxValueLeft>
                  <div className={`w-[22px] h-[22px] rounded-[50%] bg-[${checkType(typeTran)}]`}></div>
                </BoardWrapper.BoxValueLeft>
                <BoardWrapper.BoxValueCenter>
                  <BoardWrapper.BoxValueNumber>
                    <BoardWrapper.BoxValueInput
                      type="text"
                      onChange={(e) => handleChangeValue(e.target.value)}
                      value={valueNumber}
                    />
                  </BoardWrapper.BoxValueNumber>
                </BoardWrapper.BoxValueCenter>
                <BoardWrapper.BoxValueRight>
                  <span className='text-[#B9D2FF]'>USD</span>
                </BoardWrapper.BoxValueRight>
              </BoardWrapper.BoxValue>
              {/* item when responsive start */}
              <BoardWrapper.WrapperCountres>
                <BoardWrapper.Countdownres>{count}s</BoardWrapper.Countdownres>
              </BoardWrapper.WrapperCountres>
              {/* item when responsive start */}
            </BoardWrapper.ValueBoxres>
            {/* option value */}
            <BoardWrapper.BoxValueOption>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 5 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(5))}
              >
                +5
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 10 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(10))}
              >
                +10
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 15 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(15))}
              >
                +15
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 20 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(20))}
              >
                +20
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 25 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(25))}
              >
                +25
              </BoardWrapper.BoxValueOptionItem>
            </BoardWrapper.BoxValueOption>
            {/* bảng điều khiển */}
            <BoardWrapper.Control>
              <BoardWrapper.WrapperCount>
                <BoardWrapper.Countdown>{count}s</BoardWrapper.Countdown>
                <span >Thời gian còn lại</span>
              </BoardWrapper.WrapperCount>
              <div className='flex gap-[10px] w-full'>
                <BoardWrapper.BtnUP
                  onClick={() => handleTrading('BUY')}
                  disabled={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? true : false}
                  className={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? 'opacity-40 cursor-default' : ''}
                >
                  <span>Buy</span>
                  <img className='w-[30px] h-[18px]' src={up} />
                </BoardWrapper.BtnUP>
                <BoardWrapper.BtnDown
                  onClick={() => handleTrading('SELL')}
                  disabled={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? true : false}
                  className={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? 'opacity-40 cursor-default' : ''}
                >
                  <span>Sell</span>
                  <img className='w-[30px] h-[18px]' src={down} />
                </BoardWrapper.BtnDown>
                {/* btnclose when responsive start */}
                <BoardWrapper.BoxCloseres
                  onClick={handleCancel}
                  disabled={typeTran == "FREE" || count <= '10' ? true : false}
                  className={typeTran == "FREE" || count <= '10' ? 'opacity-40 cursor-default' : ''}
                >
                  <AiOutlineCloseCircle className='text-[29px] text-[#fff]' />
                </BoardWrapper.BoxCloseres>
                {/* btnclose when responsive end */}
              </div>
              <BoardWrapper.BtnCancel
                onClick={handleCancel}
                disabled={typeTran == "FREE" || count <= '10' ? true : false}
                className={typeTran == "FREE" || count <= '10' ? 'opacity-40 cursor-default' : ''}
              >Huỷ giao dịch</BoardWrapper.BtnCancel>
            </BoardWrapper.Control>
          </BoardWrapper.Value>
          {/* history when responsive start */}
          {/* lịch sử phiên */}
          <BoardWrapper.History>
            {/* header */}
            <BoardWrapper.HistoryHead>
              <span className='text-[#B9D2FF]'>100 kết quả mới</span>
              <BoardWrapper.HistoryUp>
                <span>Tăng</span>
                <span className='text-[#fff]'>{results?.countBuy}</span>
              </BoardWrapper.HistoryUp>
              <BoardWrapper.HistoryDown>
                <span>Giảm</span>
                <span className='text-[#fff]'>{results?.countSell}</span>
              </BoardWrapper.HistoryDown>
            </BoardWrapper.HistoryHead>
            {/* body */}
            <div className='overflow-x-scroll w-screen'>
              <BoardWrapper.HistoryBiList>
                <BoardWrapper.HistoryBi>
                  {
                    customPathFive.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathFour.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathThree.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathTwo.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathOne.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
              </BoardWrapper.HistoryBiList>
            </div>
            <BoardWrapper.Historypage>
              <div className='w-[13px] h-[11px] rounded-[2px] bg-[#B9D2FF]'></div>
              <div className='w-[13px] h-[11px] rounded-[2px] bg-[#21286B]'></div>
              <div className='w-[13px] h-[11px] rounded-[2px] bg-[#21286B]'></div>
            </BoardWrapper.Historypage>
          </BoardWrapper.History>
          {/* history when responsive end */}
          {/* nav when responsive start */}
          <BoardWrapper.Navbar>
            <BoardWrapper.NavItem
              className={nav == 1 ? "bg-gradient-to-r from-[#00B7AE] to-[#0353BB]" : ''}
              onClick={() => dispatch(changeNav(1))}
            >
              Giao dịch của bạn
            </BoardWrapper.NavItem>
            <BoardWrapper.NavItem
              className={nav == 2 ? "bg-gradient-to-r from-[#00B7AE] to-[#0353BB]" : ''}
              onClick={() => dispatch(changeNav(2))}
            >
              Giao dịch trực tiếp
            </BoardWrapper.NavItem>
            <BoardWrapper.NavItem
              className={nav == 3 ? "bg-gradient-to-r from-[#00B7AE] to-[#0353BB]" : ''}
              onClick={() => dispatch(changeNav(3))}
            >
              Tiền tệ
            </BoardWrapper.NavItem>
          </BoardWrapper.Navbar>
          {/* nav when responsive end */}
          {/* notication */}
     
<BoardWrapper.Notication ref={notication} style={{ display: showNotification ? 'flex' : 'none' }}>
        <BoardWrapper.NoticationBtn src={close} />
        <BoardWrapper.NoticationIcon src={finalStatus?.status === "LOSE" ? lose : win} />
        <BoardWrapper.NoticationValue className={finalStatus?.status === "LOSE" ? 'text-[#FF6E75]' : 'text-[#23FF49]'}>
          {finalStatus?.status === "LOSE" ? '-' : '+'} {formatter.format(finalStatus?.profit)}
        </BoardWrapper.NoticationValue>
      </BoardWrapper.Notication>
        </BoardWrapper.BoxTop>
        <BoardWrapper.BoxBottom className={nav == 1 ? 'max-md:flex' : 'max-md:hidden'}>
          <BoardWrapper.BoxBottomTitle>Giao dịch của bạn</BoardWrapper.BoxBottomTitle>
          <BoardWrapper.Table>
            <thead>
            <BoardWrapper.TrHead>
              <BoardWrapper.Th className="pl-[10px] max-md:pl-[15px]">
                <BoardWrapper.Bi></BoardWrapper.Bi>
              </BoardWrapper.Th>
              <BoardWrapper.Th>Thời gian</BoardWrapper.Th>
              <BoardWrapper.Th>Loại tiền</BoardWrapper.Th>
              <BoardWrapper.Th className="pr-[10px] max-md:pl-[15px]">Tiền</BoardWrapper.Th>
            </BoardWrapper.TrHead>
            </thead>
            <tbody>
            {
              history.map((item, i) => (
                <BoardWrapper.Tr key={i}>
                  <BoardWrapper.Td className="pl-[10px] max-md:pl-[15px]">
                    {
                      item.position == "BUY" ?
                        <BoardWrapper.BiGreen></BoardWrapper.BiGreen> :
                        <BoardWrapper.BiRed></BoardWrapper.BiRed>
                    }
                  </BoardWrapper.Td>
                  <BoardWrapper.Td>{format(new Date(item.createdAt), "dd/MM/yy")}</BoardWrapper.Td>
                  <BoardWrapper.Td>USD/BTC</BoardWrapper.Td>
                  <BoardWrapper.Td className={item.status == 'LOSE' ? 'text-[#FF6E75] pl-[10px] max-md:pl-[15px]' : 'text-[#23FF49] pl-[10px] max-md:pl-[15px]'}>{item.status == 'LOSE' ? '-' : '+'}{formatter.format(item.profit)}</BoardWrapper.Td>
                </BoardWrapper.Tr>
              ))
            }
            </tbody>
          </BoardWrapper.Table>
        </BoardWrapper.BoxBottom>
      </BoardWrapper.Body>
    </BoardWrapper>
  )
}

export default BoardControl
