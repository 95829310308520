import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { genCandle, genCandles } from "./data";
import { calcPathD, calcValueY } from "./Candle";

const Direction = () => (Math.random() < 0.5 ? -1 : 1)
const Random = (rand) => Math.random() * rand
const RandomProbabilityZero = (rand) => (Math.random() < 0.3 ? 0 : Random(rand))
const Round = (num) => Math.round(num * 100) / 100



function CandleChart({ nen }) {
    const resuilt = useSelector(state => state.nen.value);
    // console.log(resuilt);

    function genCandle(prevCandleC) {

        const O = prevCandleC
        // const C = resuilt === "BUY" ? ((Round(O + Direction() * Random(500))) + 500) : ((Round(O + Direction() * Random(500))) - 500)
        const C = Round(O + Direction() * Random(500))
        const H = Round((O < C ? C : O) + RandomProbabilityZero(300))
        const L = Round((O > C ? C : O) - RandomProbabilityZero(300))

        return { O, H, L, C }
    }

    function genCandles(initNum, amount) {
        let candles = [genCandle(initNum)]
        Array.from(Array(amount)).forEach((x, i) => {
            candles.push(genCandle(candles[i].C))
        })
        return candles
    }

    let candles = genCandles(10000, 40);
    const [pathD, setPathD] = useState(calcPathD(candles));
    const [pointer, setPointer] = useState({ x: -100, y: -100, candleIndex: -1 });
    // console.log(nen);


    useEffect(() => {
        let setIntervalId = setInterval(() => {
            candles.shift();
            candles.push(genCandle(candles[candles.length - 1].C));
            setPathD(calcPathD(candles));
        }, 2000);
        return () => {
            clearInterval(setIntervalId);
        };
    }, []);
    // useEffect(() => {
    //     candles.shift();
    //     candles.push(genCandle(candles[candles.length - 1].C));
    //     setPathD(calcPathD(candles));
    // }, [resuilt])

    const handleMouseMove = (e) => {
        let x = e.nativeEvent.layerX - 460;
        let y = e.nativeEvent.layerY - 150;
        let candleIndex = Math.floor(x / 24);
        x = candleIndex * 24 + 10;
        setPointer({ x, y, candleIndex });
    };
    // const handleMouseLeonMouseLeave = (e) => {
    //     setPointer({ x: -100, y: -100, candleIndex: -1 });
    // };

    const candleSelected = candles[pointer.candleIndex];
    const color = candleSelected?.O > candleSelected?.C ? "red" : "green";
    let delta = (candleSelected?.C - candleSelected?.O).toFixed(2);
    delta = delta > 0 ? `+${delta}` : delta;

    const pointerValueY = calcValueY(pointer.y);
    return (
        <>
            <div className="info max-md:hidden">
                <div className="label">{"Open "}</div>
                <div className={`value ${color}`}>{candleSelected?.O}</div>
                <div className="label">{"High "}</div>
                <div className={`value ${color}`}>{candleSelected?.H}</div>
                <div className="label">{"Low "}</div>
                <div className={`value ${color}`}>{candleSelected?.L}</div>
                <div className="label">{"Close "}</div>
                <div className={`value ${color}`}>{candleSelected?.C}</div>
                <div className={`value ${color}`}>{delta}</div>
            </div>
            <svg
                width="100%"
                height="100%"
                className="max-md:mt-0"
                onMouseMove={handleMouseMove}
            // onMouseLeave={handleMouseLeonMouseLeave}
            >
                <path fill="#ee4947" stroke="#ee4947" d={pathD.dRed} ></path>
                <path fill="#22f6db" stroke="#22f6db" d={pathD.dGreen} ></path>

                <line
                    x1={pointer.x}
                    y1={0}
                    x2={pointer.x}
                    y2={800}
                    stroke="#888"
                    strokeDasharray="5"
                />
                <line
                    x1={0}
                    y1={pointer.y}
                    x2={1130}
                    y2={pointer.y}
                    stroke="#888"
                    strokeDasharray="5"
                />
                <g>
                    <line x1={1130} y1={0} x2={1130} y2={600} stroke="#666" />
                    <text x={1135} y={pointer.y + 6} fill="#aaa">
                        {pointerValueY}
                    </text>
                </g>
            </svg>
        </>
    );
}

export default CandleChart;
