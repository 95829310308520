import React from 'react';

class ChatBoWidget extends React.Component {
  componentDidMount() {
    // Cài đặt Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right', // Có thể là 'left' hoặc 'right'
      locale: 'vn', // Ngôn ngữ
      type: 'expanded_bubble', // [standard, expanded_bubble]
      launcherTitle: 'BESTBID'
    };

    // Gọi Chatwoot script
    const BASE_URL = process.env.REACT_APP_CHATURL; // Truy cập biến môi trường
    const CHAT_TOKEN = process.env.REACT_APP_IDCHAT; // Truy cập ID chat

    (function(d, t) {
      const g = d.createElement(t);
      const s = d.getElementsByTagName(t)[0];
      g.src = `${BASE_URL}/packs/js/sdk.js`; // Sử dụng template string
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);

      g.onload = function() {
        window.chatwootSDK.run({
          websiteToken: CHAT_TOKEN,
          baseUrl: BASE_URL
        });
      };
    })(document, 'script');
  }

  render() {
    return null; // Không hiển thị gì trên giao diện
  }
}

export default ChatBoWidget;
